import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import { IFluidObject } from 'gatsby-background-image';
import renderHtml from '../utils/renderHtml';
import Title from '../components/title';
import Image from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const PHILOSOPY_QUERY = graphql`
  {
    allContentfulPhilosophie {
      nodes {
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        body {
          json
        }
      }
    }
  }
`;

const PhilosophiePage: FC = () => {
  const data = useStaticQuery(PHILOSOPY_QUERY).allContentfulPhilosophie.nodes[0];
  return (
    <Layout location={Routes.PHILOSOPHY}>
      <SEO title="Team" />
      <div className="pt-8 mb-12 md:px-8 flex justify-center flex-col items-center mx-auto" style={{ maxWidth: '84rem' }}>
        <Title className="mb-4 text-center">Philosophie</Title>
        <div className="transition-all transition-250 hover:shadow-xl mt-8 shadow-lg rounded">
          <Image fluid={data.image.fluid} className="max-w-2xl xl:w-1/2 mx-auto" />
          <div className="px-4 xl:w-1/2 xl:mx-8 xl:-mt-6 xl:w-full xl:px-24 xl:py-4">
            <div className="text-gray-700 py-4 markdown-body">
              {documentToReactComponents(data.body.json)}
              <ul>
                <li>
                  <a className="pdf-link block underline text-primary" target="_blank" href="AGB's aha! Studio für Tanz und Bewegung.pdf">
                    AGB's
                  </a>
                </li>
                <li>
                  <a className="pdf-link block underline text-primary" target="_blank" href="Code of Conduct (Dance Suisse).pdf">
                    Code of Conduct
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PhilosophiePage;
